<template>
    <div>
        <Breadcrumbs :items="breadcrumbs"/>
    </div>
</template>

<script>
export default {
    data: () => ({
        breadcrumbs: [
            {
                icon : 'mdi-database'
            },
            {
                text: 'masterdata'
            },
            {
                text: 'typeofcontent'
            }
        ],
    })
}
</script>